
// Disable logging
self.__WB_DISABLE_DEV_LOGS = true;

import { clientsClaim } from 'workbox-core';
import { precacheAndRoute } from 'workbox-precaching';
import { NavigationRoute, Route, registerRoute } from 'workbox-routing';
import { NetworkOnly, StaleWhileRevalidate } from 'workbox-strategies';

// Have no clue
self.skipWaiting();
clientsClaim();

// cache webpack build
precacheAndRoute(self.__WB_MANIFEST || []);


// dont cache navigation
const navigationRoute = new NavigationRoute(new NetworkOnly({
    cacheName: 'navigations'
}));
registerRoute(navigationRoute);

// cache imgs
const imageRoute = new Route(({ request, sameOrigin }) => {
    return sameOrigin && request.destination === 'image';
}, new StaleWhileRevalidate({
    cacheName: 'workbox-images'
}));
registerRoute(imageRoute);